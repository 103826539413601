// nav, mobileside, rightmenu, foot = common

//header
.navbar-toggler:focus {
  box-shadow: none;
}

.navbar {
  min-height: var(--navHeight);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-brand {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.main_001_toplogo {
  width: 12rem;
  object-fit: contain;
}

.navbar-collapse {
  justify-content: space-between;
}

.navbar-nav {
  width: 100%;
  max-width: 50rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 3rem;
}

.nav-link {
  font-family: AppleSDGothicNeoR00;
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: var(--greyish-brown);
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 4px;
  white-space: pre;
}

.nav-buttonBox {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-buttonBox .btn {
  font-family: AppleSDGothicNeoR00;
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  width: 7.75rem;
  white-space: pre;
  padding: 0.8rem;
  margin-right: 1rem;
}

.nav-buttonBox .btn:last-child {
  margin-right: 0;
}

@media (max-width: 1400px) {
  .main_001_toplogo {
    width: 10rem;
  }

  .navbar-nav {
    padding-right: 2rem;
  }

  .nav-link {
    font-size: 1.2rem;
  }

  .navbar-brand {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
  }

  .navbar-toggler {
    margin-right: 1rem;
  }

  .nav-buttonBox .btn {
    font-size: 1rem;
    width: 6rem;
    white-space: pre;
    padding: 0.6rem;
    margin-right: 1rem;
  }
}

@media (max-width: 1200px) {
  .main_001_toplogo {
    width: 8rem;
  }
}

@media (max-width: 992px) {
  .navbar-nav {
    max-width: 100%;
    flex-direction: row;
    padding: 0.5rem 1rem;
    margin: 0;
  }

  .nav-buttonBox .btn:last-child {
    margin-right: 1rem;
  }
}

@media (max-width: 768px) {
  .navbar {
    min-height: unset;
  }

  .navbar-nav {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-toggler {
    margin-right: 0;
  }

  .navbar-brand {
    padding: 0;
  }

  #navbarNavAltMarkup {
    margin: 0;
  }

  .nav-link {
    margin-right: 0;
  }

  .nav-buttonBox .btn {
    padding: 0.5rem 1.24rem;
  }

  .nav-buttonBox .btn:last-child {
    margin-right: 0;
  }
}

@media (max-width: 700px) {
  .nav-link {
    width: 33.33%;
  }

  .nav-link:nth-child(2),
  .nav-link:nth-child(5) {
    text-align: center;
  }

  .nav-link:nth-child(3),
  .nav-link:nth-child(6) {
    text-align: right;
  }
}

@media (max-width: 576px) {
  .nav-link {
    width: 50%;
  }

  .nav-link:nth-child(odd) {
    text-align: left;
  }

  .nav-link:nth-child(even) {
    text-align: right;
  }

  .nav-buttonBox {
    justify-content: space-between;
  }

  .nav-buttonBox .btn {
    width: 100%;
  }

  .nav-buttonBox .btn:last-child {
    margin-right: 0;
  }
}

/******************************
  top alert
******************************/
.topAlert {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--greyish-brown);
  box-shadow: 0 3px 6px 0 RGBA(0, 0, 0, 0.16);
  height: 3.25rem;
  width: 100%;
  transition: all 0.5s ease;
}

.topAlert.closed {
  height: 0;
  width: 0;
  margin: 0 auto;
  overflow: hidden;
}

.topAlertTextBox {
  display: flex;
  align-items: center;
}

.topAlert p {
  font-family: AppleSDGothicNeoL00;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: var(--white);
  margin-right: 1rem;
}

.topAlert #topAlertCount {
  font-family: AppleSDGothicNeoR00;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: var(--greyish-brown);
  padding: 5px 20px;
}

#topAlertEscButton {
  position: absolute;
  right: 2rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .topAlert {
    height: 2.5rem;
    padding-left: 1rem;
    justify-content: flex-start;
  }

  .topAlert p {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  .topAlert #topAlertCount {
    padding: 0.1rem 0.75rem;
  }

  #topAlertEscButton {
    width: 1rem;
    height: 1rem;
    right: 0.5rem;
  }
}

//sidemenu
.rightFloatingMenu {
  position: fixed;
  right: 0;
  top: 20%;
  width: 2rem;
  box-shadow: 0 0 10px 0 RGBA(0, 0, 0, 0.16);
  cursor: pointer;
  transition: all 0.5s ease;
  z-index: 999;
}

.rfm_topBox {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 0.5rem;
  background-color: var(--spruce);
}

.rfm_topMenuBox {
  font-family: AppleSDGothicNeoL00;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
}

.rfm_topWelcomingBox {
  display: none;
}

.rightFloatingMenu:hover {
  width: 13.5rem;
}

.rightFloatingMenu:hover .rfm_topBox {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0;
  padding: 1.875rem;
  background-color: var(--spruce);
}

.rightFloatingMenu:hover .rfm_topMenuBox {
  display: none;
}

.rightFloatingMenu:hover .rfm_topWelcomingBox {
  display: block;
}

.rfm_topBox img {
  width: 9rem;
  margin-bottom: 1.875rem;
}

.rfm_topBox .rfm_welcomingText {
  font-family: AppleSDGothicNeoL00;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
  margin-bottom: 0.625rem;
  white-space: pre;
}

.rfm_topBox .rfm_userName {
  font-size: 1.25rem;
}

.rfm_topBox .rfm_alertText {
  font-family: AppleSDGothicNeoL00;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
  white-space: pre;
}

.rfm_menuBox {
  position: relative;
  background-color: var(--white);
  border-bottom-left-radius: 5px;
  display: none;
}

.rightFloatingMenu:hover .rfm_menuBox {
  display: block;
}

.rfm_menuItem,
.rfm_subMenuItem {
  padding: 0 1.78125rem;
  cursor: pointer;
}

.rfm_menuItem > p,
.rfm_subMenuItem > p {
  font-family: AppleSDGothicNeoSB00;
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  padding: 1.6875rem 0 1.6875rem 0.5rem;
  padding-bottom: 1.6875rem;
  border-bottom: 1px solid var(--white-five);
  white-space: pre;
}

.rfm_menuItem:hover > p,
.rfm_subMenuItem:hover > p {
  font-family: AppleSDGothicNeoSB00;
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: var(--spruce);
  white-space: pre;
}

.rfm_subMenu {
  position: absolute;
  top: 0;
  right: -13.5rem;
  width: 0;
  background-color: var(--white);
  box-shadow: 0 0 10px 0 RGBA(0, 0, 0, 0.16);
  border-right: 1px solid var(--white-five);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: -1;
  transition: width 0.5s ease;
  overflow: hidden;
}

.rfm_menuItem:hover > .rfm_subMenu {
  display: block;
  right: 13.5rem;
  width: 15rem;
}

@media (max-width: 991px) {
  .rightFloatingMenu {
    display: none;
  }
}
//mobile
body.stopScrolling {
  height: 100%;
  overflow: hidden;
}

.mobileSideMenuOuter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: RGBA(0, 0, 0, 0.16);
  display: none;
  overflow: hidden;
  overscroll-behavior: contain;
}

.mobileSideMenuOuter.actived {
  display: block;
}

.mobileSideMenu {
  position: fixed;
  top: 0;
  right: -101%;
  width: 260px;
  height: 100vh;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: white;
  z-index: 999;
  transition: right 0.5s ease;
  overscroll-behavior: contain;
}

.mobileSideMenu.actived {
  right: 0;
}

.msm_closedButton {
  padding: 1rem 1rem 0 1.875rem;
  background-color: var(--spruce);
  font-size: 1.25rem;
  text-align: right;
  color: white;
}

.msm_closedButton img {
  width: 1.25rem;
}

.mobileSideMenu .msm_topBox {
  padding: 1.875rem;
  padding-top: 0;
  padding-bottom: 0.5rem;
  background-color: var(--spruce);
}

.mobileSideMenu .msm_topBox img {
  width: 9rem;
  margin-bottom: 1.875rem;
}

.mobileSideMenu .msm_topBox .msm_welcomingText {
  font-family: AppleSDGothicNeoL00;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
  margin-bottom: 0.625rem;
  white-space: pre;
}

.mobileSideMenu .msm_topBox .msm_userName {
  font-size: 1.25rem;
}

.mobileSideMenu .msm_topBox .msm_alertText {
  font-family: AppleSDGothicNeoL00;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
  white-space: pre;
}

.mobileSideMenu .msm_menuBox {
  width: 100%;
  height: calc(100% - 226px);
  background-color: var(--white);
  overflow: auto;
}

.mobileSideMenu .msm_menuItem {
  position: relative;
  padding: 1rem 0.5rem;
  margin: 0 1.5rem 0 2rem;
  border-bottom: 1px solid var(--white-five);
  font-family: AppleSDGothicNeoSB00;
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}

.mobileSideMenu .msm_menuItem a {
  display: block;
  width: 100%;
}

.mobileSideMenu .msm_menuItem a:hover {
  color: var(--black);
}

.mobileSideMenu .msm_menuItem .msm_subMenu {
  padding-left: 0.5rem;
}

.mobileSideMenu .msm_menuItem .msm_subMenu a {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border-bottom: 1px solid var(--white-five);
}

.mobileSideMenu .msm_menuItem .msm_subMenu a:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.mobileSideMenu .mblogoutF {
  font-family: AppleSDGothicNeoL00;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  margin-top: 1rem;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
  margin-bottom: 0.625rem;
  white-space: pre;
}

//footer
.footer {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;
  background-color: var(--white-two);
}

.footerRow1 {
  margin-bottom: 3.125rem;
}

.footerLogoBox {
  margin-right: 1.48125rem;
}

.footerTel {
  font-family: AppleSDGothicNeoL00;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: var(--greyish-brown);
  margin-bottom: 0;
}

.footerTime {
  font-family: AppleSDGothicNeoL00;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.6px;
  text-align: left;
  color: var(--black);
  margin-bottom: 0;
}

.footerIconBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.footerIconTwitter {
  margin-left: 2.625rem;
  margin-right: 2.3125rem;
}

.footerRow2 {
  margin-bottom: 1.5rem;
}

.footerRow2 .btn-link {
  font-family: AppleSDGothicNeoSB00;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: -0.6px;
  text-align: left;
  color: var(--black);
  padding: 0;
}

.footerRow2 p:first-child {
  margin-right: 2.25rem;
}

.footerRow3 {
  font-family: AppleSDGothicNeoM00;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: -0.6px;
  text-align: left;
  color: var(--black);
}

@media (max-width: 768px) {
  .footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .footerIconBox {
    justify-content: center;
  }

  .footerRow1 {
    margin-bottom: 1.5rem;
  }
}

// --------------------------------------- add ----------------------
.messageModal {
  z-index: 1500;
}

.gray * {
  color: #aeaeae !important;
}

.non-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: RGBA(0, 0, 0, 0.8);
  font-size: 1.5rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
