:root {
	--white: #ffffff;
	--beige: #f3ece4;
	--black: #191919;
	--greyish-brown: #464646;
	--sky-blue: #50b3fc;
	--greyish: #b5b5b5;
	--camel: #c5995c;
	--white-two: #fbfbfb;
	--dark: #1b2437;
	--spruce: #085e4b;
	--grey: #a1a3a0;
	--warm-grey: #7f7f7f;
	--bluish: #207cb0;
	--greeny-blue: #3bb2be;
	--tiffany-blue: #57e9ce;
	--light-aquamarine: #63ffd4;
	--greeny-blue-two: #4bc3ae;
	--beige-two: #dee999;
	--tomato: #ec403d;
	--dark-pink: #c83a75;
	--purply: #a635ac;
	--purply-two: #9934c2;
	--salmon: #ff8877;
	--peach: #ffb175;
	--apricot: #ffc774;
	--warm-grey-two: #808080;
	--greyish-two: #aeaeae;
	--warm-pink: #fa586e;
	--white-three: #d8d8d8;
	--white-four: #f7f7f7;
	--white-five: #e3e3e3;
	--rouge: #ab2031;
	--greyish-brown-two: #5b5b5b;
	--white-six: #dfdfdf;
	--white-seven: #f1f1f1;
	--black-30: rgba(0, 0, 0, 0.3);
	--black-two: #000000;
	--white-eight: #d6d6d6;
	--boxShadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
	--navHeight: 90px;
	--footerHeight: 354px;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: unset;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: var(--white-three);
}

::-webkit-scrollbar-button {
	display: none;
}

::-webkit-scrollbar-corner {
	background-color: unset;
}

body {
	font-family: AppleSDGothicNeoR00, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	color: var(--greyish-brown);
}

p {
	margin-bottom: 0;
}

a {
	color: var(--black);
	text-decoration: none;
}

.font-B00 {
	font-family: AppleSDGothicNeoB00;
}
.font-EB00 {
	font-family: AppleSDGothicNeoEB00;
}
.font-H00 {
	font-family: AppleSDGothicNeoH00;
}
.font-L00 {
	font-family: AppleSDGothicNeoL00;
}
.font-M00 {
	font-family: AppleSDGothicNeoM00;
}
.font-R00 {
	font-family: AppleSDGothicNeoR00;
}
.font-SB00 {
	font-family: AppleSDGothicNeoSB00;
}
.font-T00 {
	font-family: AppleSDGothicNeoT00;
}
.font-UL00 {
	font-family: AppleSDGothicNeoUL00;
}

.fontSize-12 {
	font-size: 0.75rem;
}

.fontSize-13 {
	font-size: 0.8125rem;
}

.fontSize-14 {
	font-size: 0.875rem;
}

.fontSize-15 {
	font-size: 0.9375rem;
}

.fontSize-16 {
	font-size: 1rem;
}

.fontSize-17 {
	font-size: 1.0625rem;
}

.fontSize-18 {
	font-size: 1.125rem;
}

.fontSize-19 {
	font-size: 1.1875rem;
}

.fontSize-20 {
	font-size: 1.25rem;
}

.fontSize-21 {
	font-size: 1.3125rem;
}

.fontSize-22 {
	font-size: 1.375rem;
}

.fontSize-23 {
	font-size: 1.4375rem;
}

.fontSize-24 {
	font-size: 1.5rem;
}

.fontSize-25 {
	font-size: 1.5625rem;
}

.fontSize-26 {
	font-size: 1.625rem;
}

.fontSize-27 {
	font-size: 1.6875rem;
}

.fontSize-28 {
	font-size: 1.75rem;
}

.fontSize-29 {
	font-size: 1.8125rem;
}

.fontSize-30 {
	font-size: 1.875rem;
}

.neoM00_20_greyish-brown {
	font-family: AppleSDGothicNeoM00;
	font-size: 1.25rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	color: var(--greyish-brown);
}

.neoL00_16_greyish-two {
	font-family: AppleSDGothicNeoL00;
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: -0.8px;
	color: var(--greyish-two);
}

.neoR00_14_greyish-brown-two {
	font-family: AppleSDGothicNeoR00;
	font-size: 0.875rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	color: var(--greyish-brown-two);
}

.neoM00_16_greyish-brown {
	font-family: AppleSDGothicNeoM00;
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: -0.8px;
	color: var(--greyish-brown);
}

.neoSB00_20_greyish-brown {
	font-family: AppleSDGothicNeoSB00;
	font-size: 1.25rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.4;
	letter-spacing: normal;
	color: var(--greyish-brown);
}

.neoL00_18_greyish-brown {
	font-family: AppleSDGothicNeoL00;
	font-size: 1.125rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.22;
	letter-spacing: -0.9px;
	color: var(--greyish-brown);
}

.neoM00_25_greyish-brown {
	font-family: AppleSDGothicNeoM00;
	font-size: 1.5625rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.4;
	letter-spacing: -1.25px;
	color: var(--greyish-brown);
}

.neoR00_18_rouge {
	font-family: AppleSDGothicNeoR00;
	font-size: 1.125rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.28;
	letter-spacing: -0.9px;
	color: var(--rouge);
}

.no-focus:focus {
	outline: none;
	box-shadow: none;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-auto {
	cursor: auto;
}

.text-greyish-brown {
	color: var(--greyish-brown);
}

.cmt-10 {
	margin-top: 0.625rem !important;
}

.cmb-10 {
	margin-bottom: 0.625rem !important;
}

.cmy-10 {
	margin-top: 0.625rem !important;
	margin-bottom: 0.625rem !important;
}

.cmt-15 {
	margin-top: 0.9375rem !important;
}

.cmb-15 {
	margin-bottom: 0.9375rem !important;
}

.cmy-15 {
	margin-top: 0.9375rem !important;
	margin-bottom: 0.9375rem !important;
}

.cmt-20 {
	margin-top: 1.25rem !important;
}

.cmb-20 {
	margin-bottom: 1.25rem !important;
}

.cmy-20 {
	margin-top: 1.25rem !important;
	margin-bottom: 1.25rem !important;
}

.cmt-30 {
	margin-top: 1.875rem !important;
}

.cmb-30 {
	margin-bottom: 1.875rem !important;
}

.cmy-30 {
	margin-top: 1.875rem !important;
	margin-bottom: 1.875rem !important;
}

.cmt-40 {
	margin-top: 2.5rem !important;
}

.cmb-40 {
	margin-bottom: 2.5rem !important;
}

.cmb-50 {
	margin-bottom: 4rem !important;
}

.cmy-40 {
	margin-top: 2.5rem !important;
	margin-bottom: 2.5rem !important;
}

body .radius-5 {
	border-radius: 5px !important;
}

body .radius-10 {
	border-radius: 10px !important;
}

.profileImgBox {
	width: 244px;
	height: 185px;
}

@media (max-width: 768px) {
	.mbcmb-10 {
		margin-bottom: 0.625rem !important;
	}

	.nav-item .cumbw-100 {
		width: 100%;
	}

	.cumbjc {
		justify-content: unset !important;
	}
}
.btn,
.card,
.form-control,
.form-select {
	border-radius: 5px !important;
}

.btn.rounded-pill,
.card.rounded-pill,
.form-control.rounded-pill,
.form-select.rounded-pill {
	border-radius: 50em !important;
}

/******************************
  color
******************************/
body .btn.color-greyish-brown,
.color-greyish-brown {
	color: var(--greyish-brown);
}

body .btn.color-greyish-two,
.color-greyish-two {
	color: var(--greyish-two);
}

.color-greyish-brown {
	color: var(--greyish-brown);
}

.color-greyish-brown-two {
	color: var(--greyish-brown-two);
}

.color-warm-pink {
	color: var(--warm-pink);
}

body .btn.color-black,
.color-black {
	color: var(--black);
}

body .btn.color-camel,
.color-camel {
	color: var(--camel);
}

body .btn.color-rouge,
.color-rouge {
	color: var(--rouge);
}

body .btn.color-spruce,
.color-spruce {
	color: var(--spruce);
}

body .btn.color-white-three,
.color-white-three {
	color: var(--white-three);
}

/******************************
  border
******************************/
.border {
	border: 1px solid var(--white-five) !important;
}

.border-top {
	border-top: 1px solid var(--white-five) !important;
}

.border-bottom {
	border-bottom: 1px solid var(--white-five) !important;
}

.border-left {
	border-left: 1px solid var(--white-five) !important;
}

.border-right {
	border-right: 1px solid var(--white-five) !important;
}

.border-707070 {
	border-color: #707070 !important;
}

.border-d8d8d8 {
	border-color: #d8d8d8 !important;
}

.border-greyish-brown {
	border-color: var(--greyish-brown) !important;
}

.cm-border-right {
	border-right: 1px solid var(--white-five);
}

@media (max-width: 768px) {
	.cm-border-right {
		border-right: unset;
	}
}

/******************************
  progress
******************************/
.progress {
	height: 0.25rem;
	background-color: var(--white-eight);
	border-radius: 50em;
}

.progress-bar {
	background-color: var(--spruce);
	border-radius: 50em;
}

/******************************
  only pc or mobile
******************************/
.onlyPc {
	display: block;
}

.onlyMobile {
	display: none;
}

@media (max-width: 768px) {
	.onlyPc {
		display: none;
	}

	.onlyMobile {
		display: block;
	}

	.spinner-mob {
		display: none;
		position: fixed;
		bottom: 0;
		width: 100%;
	}
}

/******************************
  button
******************************/
.btn:focus {
	outline: none !important;
	box-shadow: none !important;
}

.btn-warning {
	color: #ffffff;
	background-color: var(--camel);
	border-color: var(--camel);
}

.btn-warning:hover {
	color: #ffffff;
	background-color: #d1a568;
	border-color: #d1a568;
}

.btn-warning:focus {
	color: #ffffff;
	background-color: var(--camel);
	border-color: var(--camel);
}

.btn-outline-warning {
	color: var(--camel);
	border-color: var(--camel);
}

.btn-outline-warning:hover {
	color: #ffffff;
	background-color: var(--camel);
	border-color: var(--camel);
}

.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
	color: #ffffff;
	background-color: #d1a568;
	border-color: #d1a568;
}

.btn-dark {
	background-color: var(--greyish-brown);
	border-color: var(--greyish-brown);
	box-shadow: var(--boxShadow);
}

.btn-outline-dark {
	color: var(--greyish-brown);
	border-color: var(--greyish-brown);
}

.btn-outline-dark:hover {
	color: #ffffff;
	background-color: var(--greyish-brown);
	border-color: var(--greyish-brown);
}

.btn-danger {
	background-color: var(--rouge);
	border-color: var(--rouge);
}

.btn-success {
	background-color: var(--spruce);
	border-color: var(--spruce);
}

.btn-outline-success {
	border-color: var(--spruce);
}

.btn-white-three {
	background-color: var(--white-three);
	border-color: var(--white-three);
	color: var(--white);
}

.btn-spruce {
	background-color: var(--spruce) !important;
	border-color: var(--spruce) !important;
	color: var(--white) !important;
}

.btn-light.btn-white {
	font-family: AppleSDGothicNeoM00;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: -0.8px;
	text-align: center;
	color: var(--greyish-two);
	padding: 1rem 0.5rem;
	background-color: var(--white);
	border: 1px solid var(--white-four);
	box-shadow: var(--boxShadow);
	width: 100%;
}

.btn-outline-light {
	color: var(--white-five);
	border-color: var(--white-five);
}

.btn-whiteShadow {
	font-family: AppleSDGothicNeoL00;
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: -0.8px;
	color: var(--greyish-two);
	width: 100%;
	background-color: var(--white);
	border: 1px solid var(--white-four);
	box-shadow: var(--boxShadow) !important;
	padding: 1rem 0.5rem;
}

.btn-whiteShadow:active,
.btn-check:focus + .btn-whiteShadow:active,
.btn-whiteShadow:focus,
.btn-check:focus + .btn-whiteShadow:focus {
	color: var(--greyish-two);
	background-color: var(--white);
	border-color: var(--white-four);
	box-shadow: var(--boxShadow) !important;
}

.btn-check:checked + .btn-whiteShadow {
	color: var(--spruce);
	background-color: var(--white);
	border-color: var(--spruce);
}

/******************************
  validation
******************************/
.is-invalid {
	color: #dc3545 !important;
	border-color: #dc3545 !important;
}

.is-valid {
	color: #198754 !important;
	border-color: #198754 !important;
}

/******************************
  input
******************************/
input:focus {
	outline: none;
}

input[type='date'] {
	position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator {
	position: absolute;
	top: 0;
	left: -2rem;
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	width: calc(100%);
}

.form-check-input:checked {
	background-color: var(--camel);
	border-color: var(--camel);
}

.form-check-input:focus {
	border-color: rgba(0, 0, 0, 0.25);
	box-shadow: none;
}

.form-switch .form-check-input:checked {
	background-color: var(--rouge);
	border-color: var(--rouge);
}

.form-label {
	margin-bottom: 0;
}

.underbarInput {
	border: none;
	border-bottom: 1px solid var(--white-five);
	border-radius: unset !important;
	background-color: transparent;
	font-family: AppleSDGothicNeoM00;
	color: var(--greyish-brown);
}

input::placeholder {
	font-family: AppleSDGothicNeoL00;
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: -0.8px;
	text-align: left;
	color: var(--white-three);
}

.underbarInput.is-invalid {
	color: #dc3545 !important;
	border-color: #dc3545 !important;
}

.underbarInput.is-valid {
	color: #198754 !important;
	border-color: #198754 !important;
}

.underbarSelect {
	font-family: AppleSDGothicNeoM00;
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: -0.8px;
	text-align: left;
	border: none;
	border-bottom: 1px solid var(--white-five);
	border-radius: unset !important;
	background-color: transparent;
	color: var(--white-three);
}

/******************************
  card
******************************/
.card {
	border-radius: 5px;
	border: solid 1px var(--white-four);
	background-color: var(--white);
	box-shadow: var(--boxShadow);
}

.card-body {
	padding: 2rem;
}

.input-group-target {
	border: 1px solid transparent;
}

.card.card-boxShadow {
	background-color: var(--white);
	border: solid 1px var(--white-four);
	box-shadow: var(--boxShadow);
}

.card-link:hover {
	border-color: var(--camel);
}

.card-link:hover .card-body .form_titleSubButton {
	color: var(--white);
	background-color: var(--greyish-brown);
}

.mobile-card-body {
	flex: 1;
}

@media (max-width: 758px) {
	.card-body {
		padding: 1rem;
	}

	.mobile-card-body {
		/* flex: unset; */
	}
}

/******************************
  accordion
******************************/
.accordion-button:focus {
	border-color: rgba(0, 0, 0, 0.125);
	box-shadow: none;
}

.accordion-button.notFocus {
	border: none;
	background-color: transparent;
	color: var(--greyish-brown);
}

/******************************
  carousel
******************************/
.carousel-inner {
	height: 100%;
}

.carousel-item {
	height: 100%;
}

.carouselImg {
	min-width: 100%;
	min-height: 100%;
}

#carousel_1 {
	height: calc(100vh - var(--navHeight));
}

#carousel_1 #carousel_1_1 {
	background-image: url('../images/main_001_bg001.png');
	background-position: center;
	background-size: cover;
}

#carousel_1 #carousel_1_2 {
	background-image: url('../images/main_001_bg002.png');
	background-position: center;
	background-size: cover;
}

#carousel_1 #carousel_1_3 {
	background-image: url('../images/main_001_bg003.png');
	background-position: center;
	background-size: cover;
}

#carousel_1 #carousel_1_4 {
	background-image: url('../images/main_001_bg004.png');
	background-position: center;
	background-size: cover;
}

#carousel_1 .carousel_1Container {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	margin: 0 auto;
}

#carousel_1 .carousel_1Container .container {
	height: 294px;
}

#carousel_1 .carousel-indicators {
	position: relative;
	justify-content: flex-start;
	margin-left: 0;
	margin-right: 0;
}
#carousel_1 .carousel-indicators * {
	transition: all 0.5s ease;
}

#carousel_1 .carousel-indicators > div {
	width: 8px;
	height: 8px;
	margin: 0 9px 0 0;
	border-radius: 100%;
	background-color: var(--greyish);
	cursor: pointer;
}

#carousel_1 .carousel-indicators > div.active {
	width: 27px;
	height: 8px;
	padding: 0 1px 0 18px;
	border-radius: 4px;
	background-color: var(--camel);
}

#carousel_1 .carousel-caption {
	display: none;
	opacity: 0;
	position: absolute;
	top: 3.5rem;
	bottom: unset;
	left: 0;
	right: unset;
	width: max-content;
	max-width: 95vw;
	text-align: left;
	padding-top: 16px;
	padding-bottom: 0;
	font-family: AppleSDGothicNeoM00;
	color: var(--greyish-brown);
	z-index: 2;
}

#carousel_1 .carousel-indicators > div.active .carousel-caption {
	display: block;
	opacity: 1;
}

#carousel_1 .carousel_1Caption_1 {
	font-family: AppleSDGothicNeoR00;
	font-size: 2.4375rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.44;
	letter-spacing: -1.95px;
	text-align: left;
	color: var(--greyish-brown);
	margin-bottom: 0;
}

#carousel_1 .carousel_1Caption_2 {
	font-family: AppleSDGothicNeoM00;
	font-size: 3rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.44;
	letter-spacing: -2.4px;
	text-align: left;
	color: var(--greyish-brown);
	margin-bottom: 0;
}

#carousel_1 .carousel-caption {
	pointer-events: none;
}

#carousel_1 .carousel-caption .btn {
	width: 848px;
	max-width: 90vw;
	margin-top: 3.5rem;
	border-radius: 5px;
	background-color: var(--greyish-brown);
	font-family: AppleSDGothicNeoR00;
	font-size: 1.25rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: var(--white);
	pointer-events: all;
}

@media (max-width: 768px) {
	#carousel_1 #carousel_1_1 {
		background-image: url('../images/main_001_bg001.png');
		background-position: right;
		background-size: cover;
	}

	#carousel_1 #carousel_1_2 {
		background-image: url('../images/main_001_bg002.png');
		background-position: right;
		background-size: cover;
	}

	#carousel_1 #carousel_1_3 {
		background-image: url('../images/main_001_bg003.png');
		background-position: right;
		background-size: cover;
	}

	#carousel_1 #carousel_1_4 {
		background-image: url('../images/main_001_bg004.png');
		background-position: right;
		background-size: cover;
	}

	#carousel_1 .carousel_1Container {
		align-items: flex-end;
	}

	#carousel_1 {
		height: calc(100vh - 56px);
	}

	#carousel_1 .carousel-caption {
		top: 1.5rem;
	}

	#carousel_1 .carousel_1Caption_1 {
		color: white;
		font-size: 1.2rem;
	}

	#carousel_1 .carousel_1Caption_2 {
		color: white;
		font-family: AppleSDGothicNeoM00;
		font-size: 1.5rem;
	}

	#carousel_1 .carousel-caption .btn {
		min-height: 50px;
		margin-top: 1.5rem;
		border-radius: 5px;
		background-color: var(--greyish-brown);
		font-family: AppleSDGothicNeoR00;
		font-size: 1rem;
		line-height: normal;
		text-align: center;
		color: var(--white);
	}
}

/******************************
  wrap
******************************/
.bgWrap {
	min-height: calc(100vh - var(--navHeight));
	background-image: url('../images/login_001_bg001.png');
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: 100% auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 5.625rem;
	padding-bottom: 5.625rem;
}

.bgWrap2 {
	min-height: calc(100vh - var(--navHeight));
	//background-image: url('../images/pro-matching-001-bg-001-big.png');
	background-position: top;
	background-repeat: no-repeat;
	background-size: 100% auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 5.625rem;
	padding-bottom: 5.625rem;
}

.mainWrap {
	padding-top: 5.625rem;
	padding-bottom: 5.625rem;
}

@media (max-width: 768px) {
	.mainWrap {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.fontSize-30 {
		font-size: 1.5rem;
	}
}

/******************************
  main
******************************/
.pfBox_imageOuter {
	width: 155px;
}

.pfBox_imageSquare {
	width: 100%;
	height: 167px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #f2f2f2;
	overflow: hidden;
}

.pfBox_imageSquare img {
	max-width: 100%;
	max-height: 100%;
}

.pfBox_imageCircle {
	width: 130px;
	height: 130px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #f2f2f2;
	border-radius: 100%;
	overflow: hidden;
	margin: 0 auto;
}

.pfBox_imageCircle img {
	max-width: 100%;
	max-height: 100%;
}

.iconBox_circle {
	width: 100px;
	height: 100px;
	background-color: var(--camel);
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.875rem;
	color: var(--white);
}

.iconBox_circle img {
	width: 30px;
}

.bannerWrap {
	height: 120px;
	border: 1px solid var(--white-four);
}

.cardImage_caddie {
	width: 244px;
	//height: 203px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.cardImage_caddie_mb {
	width: 35%;
	//height: 203px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

}

.cardImage_caddie img {
	min-width: 100%;
	min-height: 100%;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.cardImage_caddie_mb img {
	min-width: 100%;
	min-height: 100%;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.supereum {
	display: block;
	color: #c5995c;
	font-weight: bold;
	margin-right: 0.5rem;
}

.supereum_icn,
.button_thumup {
	cursor: pointer;
}

.supereum_icn {
	display: none;
	width: 22px;
	height: 30px;
}

.supereum_icn.actived {
	display: block;
}
.button_thumup {
	width: 20px;
	height: 20px;
}

.nav-tabs-camel {
	border-bottom: 12px solid var(--camel);
}

.nav-tabs-camel .nav-link.tab-camel {
	text-align: center;
	background-color: var(--white);
	border: 1px solid var(--white-four);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
}

.nav-tabs-camel .nav-link.tab-camel.active {
	text-align: center;
	color: var(--white);
	background-color: var(--camel);
	border: 1px solid var(--camel);
	box-shadow: none;
}

.challengecheckedBox.originDisable {
	position: relative;
}
.challengecheckedBox.originDisable::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.65);
}
.challengecheckedBox.originDisable::after {
	content: '계약중인 대회';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	font-weight: bold;
}
@media screen and (max-width: 768px) {
	.challengecheckedBox.originDisable::after {
		font-size: 1rem;
	}
}

.msb_col {
	padding-top: 3.25rem;
	padding-bottom: 3.25rem;
}

.msb_imgBox {
	text-align: center;
}

.msb_imgBox img {
	width: 70%;
	max-width: 144px;
}

.msb_title {
	font-family: AppleSDGothicNeoL00;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: center;
	color: var(--greyish-brown);
}

.indexScheduleBoardBox {
	height: 705px;
}

.indexScheduleBoardBox .card-body {
	height: 100%;
	overflow-y: auto;
}

.indexScheduleBoard {
	padding: 24px 0 16px 0;
	border-bottom: 1px solid var(--white-three);
}

.indexScheduleBoard:first-child {
	padding-top: 0;
}

.indexScheduleBoard:last-child {
	padding-bottom: 0;
	border-bottom: none;
}

.indexScheduleBoardDateLeftBar {
	width: 4px;
	height: 1rem;
	background-color: var(--camel);
}

.indexScheduleBoardDate {
	font-family: AppleSDGothicNeoL00;
	font-size: 1rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1rem;
	letter-spacing: normal;
	text-align: left;
	color: var(--camel);
	margin-top: 2px;
	margin-left: 5px;
	margin-bottom: 0;
}

.indexScheduleBoardTitle {
	font-family: AppleSDGothicNeoSB00;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.4;
	letter-spacing: -1px;
	text-align: left;
	color: var(--black);
	margin-top: 6px;
	margin-bottom: 2px;
}

.indexScheduleSubtitle {
	font-family: AppleSDGothicNeoL00;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: normal;
	text-align: left;
	color: var(--greyish-two);
	margin-bottom: 0;
}

@media (max-width: 768px) {
	.indexScheduleBoardBox {
		height: auto;
	}

	.indexScheduleBoardBox .card-body {
		height: auto;
		overflow-y: unset;
	}
}

.challengecheckedBox.disabled * {
	color: #eee !important;
}
.challengecheckedBox.disabled .indexScheduleBoardDateLeftBar {
	background-color: #eee !important;
}

#calendar table thead tr td {
	border-right: none;
	/* border-bottom: 1px solid #D6D6D6; */
}

#calendar table tbody tr td {
	border-right: none;
}

.fc-day-sun {
	color: var(--bs-danger);
}

.fc-day-sat {
	color: var(--bs-success);
}

.fc-prev-button,
.fc-next-button {
	color: var(--bs-primary);
	background-color: #ffffff;
	border: none;
}

.fc .fc-toolbar.fc-header-toolbar {
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-bottom: solid 1px var(--white-three);
}

.fc-day-sun {
	color: var(--bs-danger);
}

.fc-day-sat {
	color: var(--bs-info);
}

.fc .fc-daygrid-day-events {
	min-height: unset !important;
	height: 0.7rem;
	margin-bottom: 0;
}

.fc .fc-scrollgrid-section,
.fc .fc-scrollgrid-section table,
.fc .fc-scrollgrid-section > td {
	border: none;
}

.fc tbody td {
	cursor: pointer;
}
