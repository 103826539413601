//index.css
.index_page {
	.indexAnotherBannerBox {
		padding: 3.75rem 0;
		background-color: RGBA(216, 216, 216, 0.9);
	}

	.indexScheduleBox {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	.indexScheduleTitle {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.5625rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.4;
		letter-spacing: -1.25px;
		color: var(--greyish-brown);
		text-align: center;
		margin-bottom: 0;
	}

	.indexScheduleCardRow {
		padding-top: 3.75rem;
	}

	.indexScheduleButton {
		display: block;
		width: 22rem;
		max-width: 80%;
		padding: 1rem;
		border-radius: 5px;
		box-shadow: 0 0 10px 0 RGBA(0, 0, 0, 0.05);
		background-color: var(--spruce);
		font-family: AppleSDGothicNeoL00;
		font-size: 1.5rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: normal;
		color: var(--white);
	}

	.indexScheduleButtonBox {
		margin-top: 3.75rem;
	}

	.indexBannerBox {
		width: 100%;
		padding: 5.75rem 0 4.25rem 0;
		background-color: var(--beige);
	}

	.indexBannerItemBox {
		width: 382px;
		max-width: 100%;
		height: 233px;
		background-color: white;
		margin-bottom: 1.5rem;
	}

	.indexBannerItem {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	.indexAppStoreBox {
		background-color: var(--spruce);
	}

	.indexDownloadButtonBox {
		align-items: stretch;
		margin-bottom: 3.5rem;
	}

	.indexDownloadButtonBox .col {
		margin-bottom: 1rem;
	}

	.indexDownloadButton {
		width: 100%;
		height: 100%;
		padding: 0.625rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.indexAppStoreTitle {
		font-family: AppleSDGothicNeoSB00;
		font-size: 3rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.44;
		letter-spacing: -2.4px;
		text-align: left;
		color: var(--white);
		margin-bottom: 1.875rem;
	}

	.indexAppStoreSubtitle {
		font-family: AppleSDGothicNeoR00;
		font-size: 1.5625rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.48;
		letter-spacing: -1.25px;
		text-align: left;
		color: var(--white);
	}

	.indexAppStoreMockupImg {
		max-width: 100%;
		padding-top: 2rem;
	}

	@media (max-width: 768px) {
		.indexScheduleBox {
			padding-top: 3rem;
			padding-bottom: 3rem;
		}

		.indexScheduleCardRow {
			padding-top: 3rem;
			padding-bottom: 3rem;
		}

		.indexScheduleCardRow > div:first-child {
			margin-bottom: 3rem;
		}

		.indexBannerItemBox {
			width: 100%;
			height: auto;
			background-color: white;
		}

		.indexAppStoreTitle {
			font-size: 1.5rem;
			letter-spacing: normal;
		}

		.indexAppStoreSubtitle {
			font-size: 1rem;
			letter-spacing: normal;
			margin-bottom: 5rem;
		}
	}
}
//notice.css
.notice_page {
	/* outer card box */
	.tcwbg_outer {
		position: relative;
	}

	.tcwbg_title .bg_linearGradient_01 {
		font-weight: normal;
	}

	.tcwbg_bgBox {
		width: 100%;
		height: 28rem;
	}

	.tcwbg_contentWrap {
		width: 100%;
		margin-top: -16rem;
		padding-bottom: 3rem;
		border-bottom: 1px solid #d6d6d6;
	}

	.tcwbg_title {
		text-align: center;
		font-size: 2.625rem;
		font-weight: bold;
		line-height: 3.75rem;
		color: var(--font-black);
		margin-bottom: 2rem;
	}

	.tcwbg_card-body {
		padding: 3rem 3rem;
		display: flex;
		flex-direction: column;
		-webkit-flex-direction: column;
		width: 100%;
		border-radius: 10px;
		border: solid 1px #e3e3e3;
		background-color: #ffffff;
		overflow: hidden;
	}

	.innerBox {
		display: flex;
		flex-direction: column;
		-webkit-flex-direction: column;
		width: 100%;
		flex: 1;
		padding-left: 212px;
		padding-top: 110px;
		padding-right: 211px;
		padding-bottom: 63px;
	}

	.mobile-show {
		display: none;
	}
	.mobile-hide {
		display: block;
	}

	.innerHeader {
		display: flex;
		flex-direction: column;
		-webkit-flex-direction: column;
		align-items: center;
	}

	.innerHeader .searchbox {
		width: 100%;
		padding: 11px 18px 11px 35px;
		border-radius: 10px;
		box-shadow: 0 3px 6px 0 RGBA(0, 0, 0, 0.16);
		border: solid 1px #e3e3e3;
		background-color: #ffffff;
		display: flex;
	}
	.searchbox * {
		border: none;
		background-color: transparent;
	}
	.searchbox .search-input {
		flex: 1;
		font-size: 1.125rem;
	}
	.searchbox i {
		font-size: 1.6rem;
	}
	input.search-input:focus {
		outline: none;
	}

	.innerContent {
		margin-top: 46px;
	}
	.innerContent p.title {
		font-size: 1ren;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		text-align: left;
		color: var(--greyish-brown);
	}
	.pboard {
		margin-top: 35px;
	}
	.pboard-item {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		padding-bottom: 29.5px;
		cursor: pointer;
	}
	.pboard-item:not(:last-child) {
		border-bottom: solid 1px #e3e3e3;
		margin-bottom: 29.5px;
	}
	.pboard .item-left {
		flex: 1;
	}
	.pboard .item-left .title {
		font-size: 1rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: normal;
		text-align: left;
		color: var(--greyish-brown);
	}

	.pboard .item-left .subtitle {
		margin-top: 10px;
		font-size: 0.825rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.36;
		letter-spacing: normal;
		text-align: left;
		color: #aeaeae;
	}
	.pboard .item-right {
		width: max-content;
		display: flex;
		align-items: center;
	}
	.pboard .item-right .item-right {
		text-align: right;
	}

	.pboardcount {
		display: flex;
		justify-content: center;
	}
	.pboardcount a {
		margin: 0 0.5rem;
	}
	.pboardcount a.selected {
		text-decoration: underline;
	}

	.pboard button.updownbtn {
		border: none;
		background-color: transparent;
	}
	button.updownbtn img.up {
		transition: all ease 0.5s;
		transform: rotate(0deg);
	}
	button.updownbtn img.down {
		transition: all ease 0.5s;
		transform: rotate(180deg);
	}
	.pboard-item .pboard-subitem {
		width: 100%;
		padding: 30px 0 0 30px;
		display: none;
	}
	.pboard-item .pboard-subitem.itemnone {
		display: block;
	}

	@media screen and (max-width: 767px) {
		.tcwbg_bgBox.washRequest_topBg {
			display: none;
		}
		.innerBox {
			padding: 10px;
		}
		.tcwbg_contentWrap {
			margin-top: var(--top-bar-height);
			border: none;
		}

		.tcwbg_title {
			text-align: left;
			font-size: 2rem;
			line-height: normal;
			visibility: hidden;
		}

		.tcwbg_contentWrap > .container > .card {
			border: none;
			padding: 0;
			box-shadow: none;
		}

		.tcwbg_card-body {
			padding: 0 !important;
			border: none;
			height: fit-content;
		}

		.form-group:not(:first-child) {
			margin-top: 20px;
		}
		.mobilerow {
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			align-items: center;
		}

		.tcwbg_contentWrap {
			margin-top: 0;
		}
		.tcwbg_bgBox.washRequest_topBg {
			display: none;
		}
		.mobile-show {
			display: block;
		}
		.mobile-hide {
			display: none;
		}
		.pboardcount {
			display: none;
		}
		.innerHeader {
			align-items: flex-start;
		}
		.innerHeader p {
			text-align: left;
		}
		.innerHeader p.mypoint {
			font-weight: bold;
			font-size: 1.5rem;
		}
		.innerContent {
			margin-top: 30px;
		}
		.pboard {
			margin-top: 20px;
		}
		.pboard-item:not(:last-child) {
			padding-bottom: 18px;
			margin-bottom: 18px;
		}
		.innerHeader .searchbox {
			padding: 5px 10px;
		}
	}
}
//form.css
.form_page {
	.form_mw-662 {
		max-width: 662px;
	}

	.form_topTitle {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.875rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.27;
		letter-spacing: normal;
		text-align: center;
		color: var(--greyish-brown);
		margin-bottom: 3.75rem;
		word-break: keep-all;
	}

	.form_progressTitle {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.4;
		letter-spacing: normal;
		text-align: center;
		color: var(--greyish-brown);
		margin-bottom: 1.5rem;
	}

	.supereumBox {
		border: 1px solid var(--camel);
		box-shadow: 0 0 5px 0 RGB(197 153 92 / 75%);
		color: var(--black);
	}

	.progress {
		margin-bottom: 3.75rem;
	}

	.form_title {
		font-family: AppleSDGothicNeoM00;
		font-size: 1.125rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
		margin-bottom: 1.25rem;
	}

	.form_titleInfo {
		font-family: AppleSDGothicNeoL00;
		font-size: 0.75rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: -0.6px;
		text-align: left;
		color: var(--greyish-two);
		margin-left: 0.625rem;
	}

	.form_inputText {
		font-family: AppleSDGothicNeoM00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		color: var(--greyish-brown);
		display: block;
		width: 100%;
		padding: 0.375rem 0.75rem;
		background-clip: padding-box;
	}

	.form_inputTextLabel {
		font-family: AppleSDGothicNeoM00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		color: var(--greyish-brown);
		display: block;
		padding: 0.375rem 0.75rem;
		padding-left: 0;
		background-clip: padding-box;
	}

	.form_subTitle {
		font-family: AppleSDGothicNeoSB00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.8px;
		color: var(--greyish-brown);
		width: 7.8rem;
	}

	.nextPageButton {
		width: 100%;
		padding-top: 1.375rem;
		padding-bottom: 1.375rem;
		font-family: AppleSDGothicNeoR00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: center;
		color: var(--white);
		margin-bottom: 0.875rem;
	}

	.form-subButton {
		width: 8.125rem;
		padding: 0.5625rem 0.5rem;
		background-color: var(--white);
		border: 1px solid var(--greyish-brown);
		box-shadow: var(--boxShadow);
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		color: var(--greyish-brown);
	}

	.floatbtn {
		position: absolute;
		bottom: 0;
		right: 0.875rem;
		width: 2.5rem;
		height: 2.5rem;
		cursor: pointer;
	}

	.form_titleSubButton {
		font-family: AppleSDGothicNeoR00;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.36;
		letter-spacing: normal;
		color: var(--greyish-brown-two);
		background-color: var(--white-four);
		border: 1px solid var(--white-five);
		border-radius: 50em !important;
		padding: 0.3125rem 0.625rem;
	}

	.form-select.card.rounded-pill {
		font-family: AppleSDGothicNeoR00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: left;
		color: var(--greyish-brown);
		padding: 1rem 1.625rem;
	}

	.partner_radioBox {
		display: flex;
		align-items: center;
		padding: 1.625rem 2.125rem;
		margin-bottom: 1.3125rem;
		background-color: var(--white);
		border: 1px solid var(--white-four);
		border-radius: 5px;
		box-shadow: 0 0 5px 0 RGBA(0, 0, 0, 0.1);
	}

	.partner_radioBox.actived {
		border: 1px solid var(--camel);
		box-shadow: 0 0 5px 0 RGBA(197, 153, 92, 0.75);
		color: var(--black);
	}

	.partner_radioBox,
	.partner_radioBox * {
		cursor: pointer;
	}

	.partner_radioBox .form-check-input[type='radio'] {
		margin-right: 1rem;
	}

	label.partner_radioBox {
		width: 100%;
		font-family: AppleSDGothicNeoSB00;
		font-size: 1.125rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: 0.32px;
		text-align: left;
		color: var(--greyish-two);
	}

	.partner_labelBox img {
		margin-right: 1rem;
	}

	.partner_labelBox {
		display: flex;
		align-items: center;
	}

	.mb_btn_size {
		justify-content: flex-end;
	}

	.bg-white-two {
		background-color: #fbfbfb;
	}

	.bg-white-five {
		background-color: #e3e3e3;
	}

	.bg-camel {
		background-color: #c5995c;
		color: white;
	}

	.creditCardBox {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		overflow-x: auto;
		padding: 2rem 0;
	}

	.rect-box > div {
		padding-top: 100%;
		position: relative;
	}
	.rect-box > div > div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	label.checkCustom {
		position: relative;
		height: 1.3rem;
	}
	input:not(:checked) + label.checkCustom::after {
		font-family: FontAwesome;
		content: '\f111';
		font-size: 1.4rem;
		position: absolute;
		top: 0;
		left: 0;
	}
	input:checked + label.checkCustom::after {
		font-family: FontAwesome;
		content: '\f058';
		font-size: 1.4rem;
		position: absolute;
		top: 0;
		left: 0;
	}

	.indexScheduleBoardBox {
		height: 636px !important;
	}

	.request_checkbox_img {
		width: 32px;
		height: 32px;
		background-image: url('../images/register-pro-001-checkbox-gray.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	.request_checkbox:checked + label .request_checkbox_img {
		background-image: url('../images/register-pro-001-checkbox-bk.png');
	}

	.request_checkbox_outer {
		max-width: 600px;
		margin: 0 auto;
	}

	.modal_overflow_y {
		overflow-y: auto;
	}

	input[type='text-right'] {
		text-align: right;
	}

	input.p-right::-webkit-input-placeholder {
		text-align: right;
	}
	input.p-right::-moz-placeholder {
		text-align: right;
	}
	input.p-right:-ms-input-placeholder {
		text-align: right;
	}
	input.p-right:-moz-placeholder {
		text-align: right;
	}
	input.p-right::placeholder {
		text-align: right;
	}

	.tcFBox {
		border: 1px solid #c5995c;
		border-radius: 10px;
		padding: 1rem;
	}

	@media (max-width: 768px) {
		.nextPageButton {
			margin-bottom: 1.875rem;
		}

		.pfBox_imageOuter {
			width: 100%;
		}

		.pfBox_imageSquare {
			height: auto;
		}

		.partner_radioBox {
			padding: 0.7rem;
		}

		.partner_radioBox .form-check-input[type='radio'] {
			display: none;
		}

		.partner_labelBox img {
			margin-right: 0;
		}

		.partner_labelBox {
			flex-direction: column;
			-webkit-box-direction: reverse;
		}

		.mb_btn_size {
			justify-content: center;
		}

		.bg-white-two {
			background-color: #fbfbfb;
		}

		.bg-white-five {
			background-color: #e3e3e3;
		}

		.bg-camel {
			background-color: #c5995c;
		}

		.indexScheduleBoardBox {
			height: auto !important;
		}

		.mobiletxt {
			display: flex;
			margin-bottom: 1.25rem;
		}
	}

	.js-onclickHref {
		cursor: pointer;
	}
}
//pro_form.css
.pro_form {
	.padding-top > td {
		padding-top: 1.37rem !important;
	}
	.pro_my_title_text {
		font-family: AppleSDGothicNeoM00;
		font-size: 3rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.44;
		letter-spacing: -2.4px;
		text-align: left;
		color: var(--greyish-brown);
	}

	/* ì´ë¯¸ì§€ ë°•ìŠ¤ css ë¶€ë¶„ */
	.innerBox {
		display: flex;
		flex-direction: column;
		-webkit-flex-direction: column;
		flex: 1;
		width: 100%;
		padding-left: 212px;
		padding-top: 110px;
		padding-right: 211px;
		padding-bottom: 63px;
	}
	.reg_inputBox {
		margin-bottom: 1.875rem;
	}

	.reg_inputBox .form-label {
		font-family: AppleSDGothicNeoM00;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
	}
	.del_label {
		height: 1.31rem;
		font-family: AppleSDGothicNeoM00;
		font-size: 1.12rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
		border-bottom: 1px solid;
	}

	.reg_buttons {
		width: 8.125rem;
		margin-left: 1rem;
		padding: 0.5625rem 0.5rem;
		background-color: var(--white);
		border: 1px solid var(--greyish-brown);
		box-shadow: var(--boxShadow);
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		color: var(--greyish-brown);
	}

	.certificationCheckBox,
	.certificationTime,
	.certificationText {
		display: none;
	}

	.certificationCheckBox {
		margin-top: 0.5rem;
		justify-content: space-between;
	}
	.certificationText {
		font-family: AppleSDGothicNeoR00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		text-align: left;
		color: var(--rouge);
	}

	.certificationCheckBox {
		display: flex;
		justify-content: space-between;
		margin-bottom: 2rem;
	}

	.form_termText {
		font-family: AppleSDGothicNeoL00;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: center;
		color: var(--greyish-brown);

		margin-left: 6px;
	}

	.form_settingBtnText {
		font-family: AppleSDGothicNeoR00;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.36;
		letter-spacing: normal;
		text-align: left;
		color: var(--greyish-brown-two);
	}
	.form_termBox {
		border-radius: 5px;
		border: solid 1px var(--camel);
		background-color: var(--white);
	}
	.form_settingBtn {
		width: 4.5rem;
		height: 1.68rem;

		padding: 0px 10px;
		border-radius: 50px;
		border: solid 1px var(--white-five);
		background-color: var(--white-two);
	}
	.contract_contentTopTime {
		font-family: AppleSDGothicNeoSB00;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: normal;
		text-align: left;
		color: var(--greyish-brown);
	}

	.contract_subtime {
		font-family: AppleSDGothicNeoR00;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.36;
		letter-spacing: normal;
		text-align: left;
		color: var(--greyish-two);
	}

	.contract_contentText {
		font-family: AppleSDGothicNeoL00;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		color: var(--black);
	}
	.contract_contentAfterText {
		font-family: AppleSDGothicNeoL00;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		color: var(--greyish-two);
	}
	.contract_topTitleText {
		font-family: AppleSDGothicNeoSB00;
		font-size: 3rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.44;
		letter-spacing: -2.4px;
		text-align: left;
		color: var(--greyish-brown);
	}
	.contract_topSubTitleText {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.56rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: -1.25px;
		text-align: left;
		color: var(--greyish-brown);
		/* line-height: 2.4; */
	}

	.contract_subtime {
		font-family: AppleSDGothicNeoR00;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.36;
		letter-spacing: normal;
		text-align: left;
		color: var(--greyish-two);
	}

	.contract_testb {
		width: 122px;
		height: 54px;
		margin: 0 10px 0 0;
		padding: 15px 26px;
		border-radius: 50px;
		box-shadow: 0 0 10px 0 RGBA(0, 0, 0, 0.05);
		border: solid 1px var(--white-four);
		background-color: var(--white);
	}

	.border_bottom_white_five > th {
		border-bottom: none !important;
		border-bottom: solid 1px var(--white-five) !important;
	}
	.input_text {
		font-family: AppleSDGothicNeoL00;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		color: var(--greyish-brown);
	}

	@media screen and (max-width: 768px) {
		.pro_matching_list_001_card {
			position: relative;
			padding-bottom: 2rem;
		}
		.pro_matching_list_001_card .abbox {
			position: absolute;
			bottom: 0.5rem;
			left: 1rem;
		}
	}
}

//register.css
.register_page {
	.bgWrap .container {
		max-width: 805px;
	}

	.loginLogoBox {
		display: flex;
		justify-content: center;
		margin-bottom: 3.75rem;
	}

	.rg001_title_1 {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.5625rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: -1.5px;
		text-align: center;
		color: var(--greyish-brown);
	}

	.rg001_title_2 {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.875rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: -1.5px;
		text-align: center;
		color: var(--greyish-brown);
		margin-bottom: 3.75rem;
	}

	.rg001_radioTitle {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: -1px;
		text-align: center;
		color: var(--greyish-two);
		margin-bottom: 2.375rem;
	}

	.rg001_radioBox {
		display: flex;
		align-items: center;
		padding: 1.625rem 2.125rem;
		margin-bottom: 1.3125rem;
		background-color: var(--white);
		border: 1px solid var(--white-four);
		border-radius: 5px;
		box-shadow: 0 0 5px 0 RGBA(0, 0, 0, 0.1);
	}

	.rg001_radioBox,
	.rg001_radioBox * {
		cursor: pointer;
	}

	.rg001_radioBox .form-check-input[type='radio'] {
		margin-right: 1rem;
	}

	.rgPro002_addCBPR {
		font-family: AppleSDGothicNeoL00;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: -0.6px;
		text-align: left;
		color: var(--black);
		border: 1px solid var(--white-three);
		border-radius: 50em !important;
		padding: 0.3125rem 0.625rem;
		margin-left: 0.9rem;
	}

	.rg001_radioBox label {
		width: 100%;
		font-family: AppleSDGothicNeoSB00;
		font-size: 1.125rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: 0.32px;
		text-align: left;
		color: var(--greyish-two);
	}

	.rg001_labelBox img {
		margin-right: 1rem;
	}

	.rg001_labelBox {
		display: flex;
		align-items: center;
	}

	.registerNextButton {
		width: 100%;
		padding-top: 1.375rem;
		padding-bottom: 1.375rem;
		font-family: AppleSDGothicNeoR00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: center;
		color: var(--white);
	}

	.bgWrap .container.register002 {
		max-width: 662px;
		padding-bottom: 3.5rem;
	}

	.rg002_title {
		font-family: AppleSDGothicNeoM00;
		font-size: 1.5rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.4;
		letter-spacing: -1.25px;
		text-align: center;
		color: var(--greyish-brown);
		margin-bottom: 4rem;
	}

	.rg002_profileImageBox {
		position: relative;
		width: 11.6875rem;
		height: 11.6875rem;
		margin-top: 2.875rem;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2.125rem;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.rg002_profileImage {
		border-radius: 100%;
		overflow: hidden;
	}

	.rg002_inputBox {
		margin-bottom: 1.875rem;
	}

	.rg002_inputBox .form-label {
		font-family: AppleSDGothicNeoM00;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
	}

	.rg002_editImg {
		position: absolute;
		bottom: 0;
		right: 0.9rem;
	}

	.rg002_buttons {
		width: 8.125rem;
		margin-left: 1rem;
		padding: 0.5625rem 0.5rem;
		background-color: var(--white);
		border: 1px solid var(--greyish-brown);
		box-shadow: var(--boxShadow);
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		color: var(--greyish-brown);
	}

	.certificationCheckBox {
		display: flex;
		justify-content: space-between;
		margin-bottom: 2rem;
	}

	.rg002_certificationText {
		font-family: AppleSDGothicNeoR00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		text-align: left;
		color: var(--rouge);
	}

	.rg002_agreeBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2.25rem;
	}

	.rg002_agreeBox .btn {
		padding: 0;
		font-family: AppleSDGothicNeoM00;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
	}

	.rg002_agreeBox .form-switch .form-check-input {
		width: 2.375rem;
		height: 1.25rem;
	}

	.rg002_agreeBox2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2.25rem;
		font-family: AppleSDGothicNeoM00;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
	}

	.rg002_agreeBox2 u {
		color: var(--camel);
		cursor: pointer;
	}

	#rg002_checkServiceAgreeImg,
	#rg002_checkAgreeImg {
		cursor: pointer;
	}

	.rg002_certificationCheckBox,
	.rg002_certificationTime,
	.rg002_certificationText {
		display: none;
	}

	.rg002_certificationCheckBox {
		margin-top: 0.5rem;
		justify-content: space-between;
	}

	#rg002_checkServiceAgreeImg,
	#rg002_checkAgreeImg {
		width: 2.5rem;
	}

	#registerPro002 {
		margin-top: 1rem;
	}

	.rgPro002_title {
		font-family: AppleSDGothicNeoM00;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
		margin-bottom: 0.5rem;
	}

	.rgProCheckTourBox {
		margin-top: 1rem;
	}

	.rgProCheckTourBox .col {
		margin-bottom: 1.875rem;
	}

	.rgPro002_radio {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1.5rem;
		background-color: var(--white);
		border: 1px solid var(--white-four);
		border-radius: 5px;
		box-shadow: var(--boxShadow);
		cursor: pointer;
	}

	#checkUserNumberModal .modal-header {
		align-items: flex-start;
	}

	#addSBPRModal .modal-header {
		align-items: flex-start;
	}

	#checkUserNumberModal .modal-title,
	#addSBPRModal .modal-title,
	#checkCareerModal .modal-title {
		font-family: AppleSDGothicNeoM00;
		font-size: 25px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.4;
		letter-spacing: -1.25px;
		text-align: center;
		color: var(--greyish-brown);
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	#addSBPRModal .modal-body {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	#staticBackdropLabel {
		font-family: AppleSDGothicNeoM00;
		font-size: 25px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.4;
		letter-spacing: -1.25px;
		text-align: center;
		color: var(--greyish-brown);
	}

	.rgPro002_addSBPRLabel {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.125rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.22;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
		margin-bottom: 1.375rem;
	}

	.rgPro002_uploadButton {
		width: 8.125rem;
		margin-left: 2.875rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.rgPro002_checkUserNumberButton {
		width: 5rem;
		margin-left: 2.875rem;
	}

	.rgPro002_doneUploadText {
		display: none;
		font-family: AppleSDGothicNeoR00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: normal;
		text-align: center;
		color: var(--black);
		margin-bottom: 1.25rem;
	}

	.rgPro002_doneUploadText u {
		color: var(--camel);
	}

	#rgPro002_uploadTextInput {
		background-color: #ffffff;
		cursor: pointer;
	}

	#rgPro002_uploadTextInput:focus {
		box-shadow: none;
	}

	.rgPro002_delButton {
		display: none;
		padding: 0.3125rem 1.125rem;
		border-radius: 50em !important;
		margin-bottom: 3rem;
	}

	#rgPro002_userNumber {
		background-color: white;
	}

	#rgPro002_userNumber:focus {
		box-shadow: none;
	}

	.container.register002.registerPro002 {
		display: none;
	}

	.container.register002.registerPro003 {
		display: none;
	}

	.rgPro003_topInfoText {
		font-family: AppleSDGothicNeoL00;
		font-size: 0.875rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: -0.7px;
		text-align: left;
		color: var(--greyish-two);
		margin-bottom: 1.875rem;
	}

	.rgPro003_title {
		font-family: AppleSDGothicNeoM00;
		font-size: 1.125rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
		margin-bottom: 1.25rem;
	}

	.rgPro003_titleInfoText {
		font-family: AppleSDGothicNeoL00;
		font-size: 0.75rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: -0.6px;
		text-align: left;
		color: var(--greyish-two);
		margin-left: 0.625rem;
	}

	.rgPro003_titleInfoText2 {
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: -0.6px;
		text-align: left;
		color: var(--greyish-two);
		margin-left: 0.625rem;
	}

	.rgPro003_radioLabel {
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		color: var(--greyish-two);
		width: 100%;
		background-color: var(--white) !important;
		border: 1px solid var(--white-fore);
		box-shadow: var(--boxShadow) !important;
		padding: 1rem 0.5rem;
	}

	.rgPro003_radioLabel.actived {
		color: var(--spruce) !important;
		background-color: var(--white) !important;
		border: 1px solid var(--spruce) !important;
	}

	.rgPro003_radioLabel.is-invalid {
		color: #dc3545 !important;
		border: 1px solid #dc3545 !important;
	}

	.rgPro003_radioLabel.is-valid {
		color: #198754 !important;
		border-color: #198754 !important;
	}

	.rgPro003_underbarInput {
		width: 100%;
		margin-bottom: 1.875rem;
	}

	.rgPro003_labelBox {
		width: 3.75rem;
	}

	.rgPro003_label {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.125rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.22;
		letter-spacing: -0.9px;
		color: var(--greyish-brown-two);
	}

	.rgPro003_inputInnerText {
		font-family: AppleSDGothicNeoR00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		text-align: left;
		color: var(--greyish-brown);
		white-space: pre;
	}

	.rgPro003_noBorderInput {
		font-family: AppleSDGothicNeoM00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		color: var(--greyish-brown);
		padding: 0;
		width: 100%;
		max-width: 100%;
		text-align: right;
		padding-left: 6px;
		padding-right: 6px;
		border: none;
	}

	.rgPro003_noBorderInput::placeholder {
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		text-align: left;
		color: var(--white-three);
	}

	.rgPro003_noBorderInput:focus {
		outline: none;
	}

	#rgPro003_paymentGameBox {
		display: none;
	}

	#rgPro003_paymentGame {
		width: 100%;
	}
	.rgAgen001_name,
	.rgAgen001_memNum {
		width: 6.87rem;
	}
	.rgAgen001_memberAddBtn {
		padding-top: 9px;
		padding-right: 25px;
		padding-left: 25px;
	}
	.rgAgen001_memberAddSelect {
		width: 8.06rem;
		height: 2.5rem;
		padding-left: 30px;
		border-radius: 50px;
		box-shadow: 0 0 10px 0 RGB(0 0 0 / 5%);
		border: solid 1px var(--white-four);
		background-color: var(--white);
		margin-left: -17px;

		font-family: AppleSDGothicNeoR00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: left;
		color: var(--greyish-brown);
		/* /* margin-top: -2px; */
	}
	.rgAgen001_table {
		font-family: AppleSDGothicNeoL00;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: normal;
		text-align: left;
		color: var(--greyish-two);
	}
	.tableRow td {
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem !important;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: center;
		color: var(--black);
		border-bottom: 0 !important;
	}

	.incentiveBox {
		width: 100%;
		margin-bottom: 1rem;
	}

	.incentiveBox .incentiveItem {
		font-family: AppleSDGothicNeoSB00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.8px;
		text-align: left;
		color: var(--greyish-brown);
		align-items: center;
		margin-bottom: 1.25rem;
	}

	.incentiveItemTitle {
		width: 7.8rem;
		text-align: right;
	}

	.incentiveItemInput {
		font-family: AppleSDGothicNeoR00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		text-align: left;
		color: var(--greyish-brown);
		display: flex;
		padding-right: 0;
	}

	.incentiveItemInput input {
		width: 100%;
	}
	.regUnderbarInput {
		border: none;
		border-bottom: 1px solid var(--white-five);
		border-radius: unset !important;
		background-color: transparent;
		font-family: AppleSDGothicNeoM00;
		color: var(--greyish-brown);
		text-align: right;
	}

	.regUnderbarInput::placeholder {
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		text-align: right;
		color: var(--white-three);
	}

	#hopePriceText {
		display: none;
	}
	.chkActive {
		background-color: var(--white) !important;
		border-color: var(--spruce) !important;
		color: var(--spruce) !important;
	}
	.rgPro003_addIncentive {
		font-family: AppleSDGothicNeoM00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		color: var(--greyish-two);
		padding: 1rem;
		background-color: var(--white);
		border: 1px solid var(--white-four);
		box-shadow: var(--boxShadow);
		width: 100%;
		margin-bottom: 1.875rem;
	}

	.rgPro003_paymentJejuBox {
		display: flex;
		align-items: center;
		width: 100%;
	}

	#rgPro003_paymentJeju {
		width: 100%;
	}

	.rgPro003_checkboxLabel {
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		color: var(--greyish-two);
		background-color: var(--white);
		border: 1px solid var(--white-four);
		box-shadow: var(--boxShadow);
		width: 100%;
		height: 100%;
		padding: 1rem 0.5rem;
		word-break: keep-all;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.rgPro003_meetingPlace {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#rgPro003_endDate {
		width: 100%;
		padding: 1rem 0.5rem;
		background-color: var(--white);
		border: 1px solid var(--white-four);
		border-radius: 5px;
		box-shadow: var(--boxShadow);
		margin-bottom: 1.875rem;
		text-align: center;
	}

	#rgPro003_endDate.is-invalid {
		border-color: var(--rouge);
	}

	.rgPro003_careerItem {
		width: 100%;
		margin-bottom: 1.875rem;
		border-bottom: 1px solid var(--white-four);
	}

	#checkCareerAccordion .accordion-body .rgPro003_titleInfoText span {
		white-space: pre;
	}

	.btn-light.btn-white {
		font-family: AppleSDGothicNeoM00;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		text-align: center;
		color: var(--greyish-two);
		padding: 1rem 0.5rem;
		background-color: var(--white);
		border: 1px solid var(--white-four);
		box-shadow: var(--boxShadow);
		width: 100%;
	}

	.bgWrap .container.register004 {
		width: 846px;
	}

	.rg004_logoBox {
		display: flex;
		justify-content: center;
		margin-bottom: 3.75rem;
	}

	.rg004_welcomeText1 {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.875rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.44;
		letter-spacing: -2.4px;
		text-align: center;
		color: var(--greyish-brown);
	}

	.rg004_welcomeText2 {
		font-family: AppleSDGothicNeoL00;
		font-size: 3rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.44;
		letter-spacing: -2.4px;
		text-align: center;
		color: var(--greyish-brown);
		margin-bottom: 2.25rem;
	}

	.rg004_welcomeText2 span {
		font-family: AppleSDGothicNeoSB00;
	}

	.rg004_prText {
		font-family: AppleSDGothicNeoR00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.6;
		letter-spacing: -1px;
		text-align: center;
		color: var(--black);
		margin-bottom: 4.875rem;
	}

	.rg004_start {
		font-family: AppleSDGothicNeoR00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: center;
		color: var(--white);
		width: 100%;
		padding-top: 1.375rem;
		padding-bottom: 1.375rem;
		word-break: keep-all;
	}

	.rg004_search {
		font-family: AppleSDGothicNeoR00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: center;
		color: var(--black);
		width: 100%;
		padding-top: 1.375rem;
		padding-bottom: 1.375rem;
		word-break: keep-all;
		background-color: var(--white);
	}

	.inner-left {
		width: 187px;
		height: 187px;
		/* margin-top: 45px; */
		position: relative;
	}
	.inner-left > img {
		border-radius: 50%;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (max-width: 768px) {
		.bgWrap {
			padding-bottom: 3rem;
		}

		.bgWrap .container {
			padding-top: 7rem;
		}

		.rg001_title_2 {
			max-width: 15rem;
			margin-left: auto;
			margin-right: auto;
		}

		.rg001_radioBox {
			padding: 0.7rem;
		}

		.rg001_labelBox img {
			margin-right: 0;
		}

		.rg001_labelBox {
			flex-direction: column;
			-webkit-box-direction: reverse;
		}

		.rg001_radioBox .form-check-input[type='radio'] {
			display: none;
		}

		.bgWrap .container.register002 {
			padding-top: 3rem;
			padding-bottom: 0;
		}

		.rg002_title {
			margin-top: 0;
			margin-bottom: 2rem;
		}

		.rgPro002_checkUserNumberButton {
			margin-left: 1rem;
		}

		#addSBPRModal .modal-body {
			padding: 1rem;
		}

		.rgPro002_uploadButton {
			margin-left: 1rem;
		}

		#checkUserNumberModal .modal-title,
		#addSBPRModal .modal-title,
		#checkCareerModal .modal-title {
			padding-top: 0;
			padding-bottom: 0;
		}

		.rg004_welcomeText1 {
			font-size: 1.5rem;
		}

		.rg004_welcomeText2 {
			font-size: 2rem;
			word-break: keep-all;
		}

		.rg004_welcomeText2 span {
			white-space: pre;
		}
	}

	#incen_new {
		display: none;
	}
}

//register form.css
.re_form {
	.rgTour_profileImage {
		border-radius: 100%;
		overflow: hidden;
	}

	.rgTour_profileImageBox {
		position: relative;
		width: 11.6875rem;
		height: 11.6875rem;
		margin-top: 2.875rem;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2.125rem;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.inner-left {
		width: 187px;
		height: 187px;
		position: relative;
	}
	.inner-left > img {
		border-radius: 50%;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.rgAgen001_memberAddSelect {
		width: 8.06rem;
		height: 2.5rem;
		padding-left: 30px;
		border-radius: 50px;
		box-shadow: 0 0 10px 0 RGB(0 0 0 / 5%);
		border: solid 1px var(--white-four);
		background-color: var(--white);
		margin-left: -17px;

		font-family: AppleSDGothicNeoR00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: left;
		color: var(--greyish-brown);
	}

	.rgTour_buttons {
		width: 8.125rem;
		margin-left: 1rem;
		padding: 0.5625rem 0.5rem;
		background-color: var(--white);
		border: 1px solid var(--greyish-brown);
		box-shadow: var(--boxShadow);
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		color: var(--greyish-brown);
	}

	.rgTour_editImg {
		position: absolute;
		bottom: 0;
		right: 0.9rem;
	}

	.rgTour_agreeBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2.25rem;
	}

	.rgTour_agreeBox .btn {
		padding: 0;
		font-family: AppleSDGothicNeoM00;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
	}

	.rgTour_agreeBox .form-switch .form-check-input {
		width: 2.375rem;
		height: 1.25rem;
	}

	.rgTour_agreeBox2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: AppleSDGothicNeoM00;
		font-size: 1.12rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
	}

	.rgTour_agreeBox2 u {
		color: var(--camel);
		cursor: pointer;
	}

	#rgTour_checkServiceAgreeImg,
	#rgTour_checkAgreeImg {
		cursor: pointer;
	}

	#rgTour_checkAgreeImg,
	#rgTour_checkServiceAgreeImg {
		width: 2.06rem;
		height: 2.06rem;
	}

	#regIdChkModal .modal-header,
	.modal-footer {
		border-bottom: none;
		border-top: none;
	}

	.regInfoText {
		font-family: AppleSDGothicNeoL00;
		font-size: 0.88rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: -0.7px;
		text-align: left;
		color: var(--greyish-two);
	}
	.regTaxText {
		font-family: AppleSDGothicNeoL00;
		font-size: 0.75rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: -0.6px;
		text-align: left;
		color: var(--greyish-two);
		margin-left: 0.625rem;
	}

	.regNWExpensesText {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.12rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.22;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
	}
	.reg_subTitle {
		font-family: AppleSDGothicNeoSB00;
		font-size: 1.12rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -0.8px;
		color: var(--greyish-brown);
	}
	.regDelButton {
		width: 4.68rem;
		border-radius: 5px;
		background-color: var(--rouge);
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		text-align: left;
		color: var(--white);
	}
	.regIncenAddButton {
		font-family: AppleSDGothicNeoM00;
	}
	.regOverlapBtn {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	.regUnderbarInput {
		border: none;
		border-bottom: 1px solid var(--white-five);
		border-radius: unset !important;
		background-color: transparent;
		font-family: AppleSDGothicNeoM00;
		color: var(--greyish-brown);
		text-align: right;
	}

	.regUnderbarInput::placeholder {
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		text-align: right;
		color: var(--white-three);
	}
	.placeholderRFix::placeholder {
		text-align: right !important;
	}

	#regTourGameCountDiv,
	#hopePriceDiv {
		display: none;
	}

	#register_house2_card,
	#registerTour2_card,
	#registerPro002 {
		display: none;
	}

	.rgTour_certificationText {
		font-family: AppleSDGothicNeoR00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		text-align: left;
		color: var(--rouge);
	}

	.rgTour_certificationCheckBox {
		display: none;
		justify-content: space-between;
		margin-bottom: 2rem;
	}
	.rgTour_certificationCheckBox,
	.rgTour_certificationTime,
	.rgTour_certificationText {
		display: none;
	}

	.rgTour_certButton {
		border: 1px solid var(--greyish-brown);
		background-color: var(--white);
		box-shadow: var(--boxShadow);
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		color: var(--greyish-brown);
	}

	.rgTour_AccountButton {
		box-shadow: var(--box-shadow);
		background-color: var(--greyish-brown);
		font-family: AppleSDGothicNeoR00;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: center;
		color: var(--white);
		width: 100%;
		padding-top: 1.375rem;
		padding-bottom: 1.375rem;
	}

	#chkCareerModal .modal-title {
		font-family: AppleSDGothicNeoM00;
		font-size: 25px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.4;
		letter-spacing: -1.25px;
		text-align: center;
		color: var(--greyish-brown);
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	#reg_house_checkAgreeImg,
	#reg_house_checkAgreeImg,
	#reg_house_checkServiceAgreeImg {
		width: 2.06rem;
		height: 2.06rem;
	}

	.rgHouse_001_selectBox {
		width: 7rem;
		height: 38px;
		padding-left: 24px;
		border-radius: 27px;
		box-shadow: 0 0 10px 0 RGBA(0, 0, 0, 0.05);
		border: solid 1px var(--white-four);
		background-color: var(--white);

		font-family: AppleSDGothicNeoR00;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: left;
		color: var(--black);
	}
	.rgHouse_001_careerBtn {
		padding-top: 5px;
		width: 7rem;
		height: 38px;

		border-radius: 27px;
		box-shadow: 0 0 10px 0 RGBA(0, 0, 0, 0.05);
		border: solid 1px var(--white-four);
		background-color: var(--white);

		font-family: AppleSDGothicNeoR00;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: left;
	}
	.rgHouse_001_title {
		font-family: AppleSDGothicNeoM00;
		font-size: 1.125rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.28;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown-two);
	}

	.rgHouse_001_titleInfoText {
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: -0.6px;
		text-align: left;
		color: var(--greyish-two);
	}
	.rgHouse_002_incentiveType {
		width: 100px;
		height: 37px;
		margin: 0 12px 0 0;
		padding-top: 9px;
		/* padding-right: 36.5px; */
		padding-bottom: 9px;

		border-radius: 50px;
		box-shadow: 0 0 10px 0 RGBA(0, 0, 0, 0.05);
		background-color: var(--greyish-two);

		font-family: AppleSDGothicNeoL00;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		text-align: center;
		color: var(--white);
	}

	.rgHouse_002_incenCut {
		font-family: AppleSDGothicNeoM00;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		text-align: right;
		color: var(--greyish-brown);
	}
	.incenCutBox {
		padding-top: 12px;
		padding-left: 24px;
		padding-right: 16px;

		background-color: white;
	}

	.register002.registerPro002 {
		display: none;
	}

	.register002.registerPro003 {
		display: none;
	}

	@media (max-width: 768px) {
		.regOverlapBtn {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
		#rgTour_id {
			padding-right: 0rem;
		}
		.incenCutBox {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
}

// login.css
.login_page {
	.bgWrap .container {
		max-width: 466px;
	}

	.loginLogoBox {
		display: flex;
		justify-content: center;
		margin-bottom: 3.75rem;
	}

	.bgWrap label {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.125rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.22;
		letter-spacing: -0.9px;
		text-align: left;
		color: var(--greyish-brown);
	}

	.underbarInput {
		margin-bottom: 1.875rem;
	}

	.findLink {
		font-family: AppleSDGothicNeoL00;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		text-align: center;
		color: var(--greyish-brown);
	}

	.loginButton {
		font-family: AppleSDGothicNeoR00;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: -1px;
		color: var(--white-two);
		width: 100%;
		padding-top: 1.375rem;
		padding-bottom: 1.375rem;
		margin-top: 1.875rem;
		margin-bottom: 1.875rem;
		background-color: var(--greyish-brown);
	}

	.makeAccountLink {
		font-family: AppleSDGothicNeoM00;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: -1px;
		text-align: center;
		color: var(--greyish-brown);
	}

	@media (max-width: 768px) {
		.bgWrap {
			padding-top: 10rem;
			padding-bottom: 3rem;
		}
	}
}

.find_info {
	/* 신입 복사용 */
	.certificationText {
		font-family: AppleSDGothicNeoR00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: -0.8px;
		text-align: left;
		color: var(--rouge);
	}

	.certificationCheckBox {
		display: none;
		justify-content: space-between;
		margin-bottom: 2rem;
	}
	.certificationTime,
	.certificationText {
		display: none;
	}

	.bgWrap {
		padding-top: var(--navHeight);
	}
	/*
.bgWrap .container {
  margin-bottom: 15.1rem;
  margin-top: 11rem;
} */

	.bgWrap .card {
		max-width: 558px;
		margin: 0 auto;
	}

	.findId_inputBox {
		margin-bottom: 1.875rem;
	}

	.findId_certButtonSend {
		/* width: 8.125rem; */

		background-color: var(--greyish-brown);
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		color: var(--white);
	}

	.findId_certButton {
		/* width: 8.125rem; */
		/* padding-bottom: 5px;

  margin-left: 1px;
  margin-top: 1px; */
		border: 1px solid var(--greyish-brown);
		background-color: var(--white);
		box-shadow: var(--boxShadow);
		font-family: AppleSDGothicNeoL00;
		font-size: 1rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: -0.8px;
		color: var(--greyish-brown);
	}

	.findAccountButton {
		box-shadow: var(--box-shadow);
		background-color: var(--greyish-brown);
		font-family: AppleSDGothicNeoR00;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: center;
		color: var(--white);
		width: 100%;
		padding-top: 1.375rem;
		padding-bottom: 1.375rem;
	}
	.findIdEmail {
		font-family: AppleSDGothicNeoR00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: center;
		color: var(--rouge);
	}

	#findIdModal .modal-header {
		align-items: flex-start;
		border-bottom: none;
	}

	#findIdModal .modal-footer {
		border-top: none;
		padding-left: 2.25rem;
		padding-right: 2.25rem;
		padding-top: 5.12rem;
		padding-bottom: 1.25rem;
		/* margin-bottom: -47px;
  modal footer의 영역이 커서 저 수치를 빼주면
  버튼 밑에 하단 영역이 딱 붙음*/
	}
	#findIdModal .modal-title {
		padding-top: 0;
		padding-bottom: 0;
	}

	#findIdModal .modal-title {
		font-family: AppleSDGothicNeoM00;
		font-size: 25px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.4;
		letter-spacing: -1.25px;
		text-align: center;
		color: var(--greyish-brown);
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
	.findIdModalText {
		font-family: AppleSDGothicNeoR00;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		text-align: center;
		letter-spacing: -1px;
		color: var(--greyish-brown);
	}

	.findIdModalDiv {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	/* .item {

} */

	@media (max-width: 768px) {
		.findId_nextButton {
			margin-bottom: 1.875rem;
		}
	}
	@media (max-width: 556px) {
		/* .item {
      flex-basis: 65%;
  } */
		/* .findIdTextFix {
      font-size: 1rem;
  }

  #findId_certification {
      font-size: 1rem;
  } */
	}
}

.matching {
	.bannerWrap {
		height: auto;
	}
}

//traningProgram.css
.traning_page {
	.mb-5rem {
		margin-bottom: 5rem;
	}

	.tpContainer .topTitleText {
		font-family: AppleSDGothicNeoSB00;
		font-size: 3rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.44;
		letter-spacing: -2.4px;
		color: var(--greyish-brown);
		margin-top: 6.25rem;
		margin-bottom: 0.5rem;
	}

	.tpContainer .topSubTitleText {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.5625rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: -1.25px;
		color: var(--greyish-brown);
		margin-bottom: 3.125rem;
		word-break: keep-all;
	}

	.tpContainer .nav-link {
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		width: 100% !important;
		padding-top: 1rem;
		margin-top: 0;
	}

	#tpTabContent {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	.tabTitle {
		font-family: AppleSDGothicNeoM00;
		font-size: 1.875rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: center;
		color: var(--greyish-brown);
	}

	.tabContentText {
		font-family: AppleSDGothicNeoL00;
		font-size: 1.5625rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: -1.25px;
	}

	.curriculumImg {
		width: 100%;
		max-width: 320px;
	}

	#tpTab002 {
		.tpTab002-content {
			height: fit-content;
			//background-image: url('../images/training-schedule-background.jpeg');
			background-position: right center;
			background-repeat: no-repeat;
			background-size: auto 100%;
		}
	}

	#tpTab003,
	#tpTab004 {
		text-align: center;
	}

	.tpContainer .btnDownload {
		width: 800px;
		max-width: 100%;
	}

	.tpContainer .kakaoIcon {
		width: 2.5rem;
		margin-right: 0.5rem;
	}

	.btn-kakao {
		background-color: #fae300;
	}

	.btn-kakao:hover {
		background-color: #ffea2e;
	}

	@media (max-width: 768px) {
		.mb-5rem {
			margin-bottom: 2rem;
		}

		.tpContainer .topTitleText {
			font-size: 2rem;
			margin-top: 3rem;
		}

		.tpContainer .topSubTitleText {
			font-size: 1.25rem;
		}

		.tpContainer .nav-link {
			font-size: 1rem;
		}

		#tpTabContent {
			padding-top: 3rem;
			padding-bottom: 3rem;
		}

		.tabTitle {
			font-size: 1.5rem;
		}

		.tabContentText {
			font-size: 1.25rem;
		}

		.curriculumBox > .col-6:nth-child(odd) {
			padding-right: 0.25rem;
		}

		.curriculumBox > .col-6:nth-child(even) {
			padding-left: 0.25rem;
		}

		.curriculumBox img {
			max-width: 100%;
		}
	}
}

.contract_page {
	.container {
		max-width: 1000px;
		margin-top: 3rem;
		margin-bottom: 3rem;
		padding: 3rem;
	}

	.mainTitle {
		font-size: 2rem;
		font-weight: bold;
		text-align: center;
		margin-bottom: 3rem;
	}

	.title {
		font-size: 1.25rem;
		font-weight: bold;
		margin-bottom: 1rem;
	}

	ol {
		list-style: none;
		margin: 0;
		padding: 0;
		counter-reset: olNumber;
	}

	ol > li {
		counter-increment: olNumber;
		padding-left: 1rem;
		margin-bottom: 1rem;
		margin-left: 0.5rem;
		text-indent: -0.5rem;
	}

	ol > li:before {
		content: counter(olNumber) '.';
		padding-right: 0.5rem;
		font-weight: bold;
	}

	ol > ul {
		margin-left: 1rem;
	}

	.liTable {
		width: calc(100% - 1.5rem);
		margin-left: 3rem;
		vertical-align: middle;
		text-align: center;
	}

	.float-btn {
		position: fixed;
		bottom: 1rem;
		right: 20%;
	}
	@media screen and (max-width: 768px) {
		.float-btn {
			right: 2rem;
		}
	}
	.float-btn button {
		font-size: 1.5rem;
		width: 3.5rem;
		height: 3.5rem;
		border-radius: 50%;
		border: none;
		background-color: #11a322;
		color: #fff;
	}
}
