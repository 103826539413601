@charset "utf-8";

@font-face {
  font-family: AppleSDGothicNeoB00;
  src: url('../fonts/AppleSDGothicNeoB.ttf') format('truetype');
}
@font-face {
  font-family: AppleSDGothicNeoEB00;
  src: url('../fonts/AppleSDGothicNeoEB.ttf') format('truetype');
}
@font-face {
  font-family: AppleSDGothicNeoH00;
  src: url('../fonts/AppleSDGothicNeoH.ttf') format('truetype');
}
@font-face {
  font-family: AppleSDGothicNeoL00;
  src: url('../fonts/AppleSDGothicNeoL.ttf') format('truetype');
}
@font-face {
  font-family: AppleSDGothicNeoM00;
  src: url('../fonts/AppleSDGothicNeoM.ttf') format('truetype');
}
@font-face {
  font-family: AppleSDGothicNeoR00;
  src: url('../fonts/AppleSDGothicNeoR.ttf') format('truetype');
}
@font-face {
  font-family: AppleSDGothicNeoSB00;
  src: url('../fonts/AppleSDGothicNeoSB.ttf') format('truetype');
}
@font-face {
  font-family: AppleSDGothicNeoT00;
  src: url('../fonts/AppleSDGothicNeoT.ttf') format('truetype');
}
@font-face {
  font-family: AppleSDGothicNeoUL00;
  src: url('../fonts/AppleSDGothicNeoUL.ttf') format('truetype');
}
